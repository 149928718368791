<template>

  <BRow class="mt-1">
    <BCol lg="6">
      <BCard class="cards totalTransactionCod">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/signpost.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Total Transaksi COD</span>
          <img
            v-b-tooltip.hover.top="'Total seluruh Nilai COD semua user dari awal dengan basis penghitungan transaksi yang sudah SELESAI.'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ IDR(metrics.total_transaction_cod) }},-
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalTransactionNonCod">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/money-send.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Total Transaksi non-COD</span>
          <img
            v-b-tooltip.hover.top="'Total seluruh nilai pembayaran Non-COD semua user dari awal dengan basis penghitungan transaksi yang sudah SELESAI'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ IDR(metrics.total_transaction_non_cod) }},-
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalShipping">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/money-2.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Total Ongkir</span>
          <img
            v-b-tooltip.hover.top="'Total seluruh nilai ongkir COD & Non-COD semua user dari awal dengan basis penghitungan transaksi yang sudah SELESAI'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ IDR(metrics.total_shipping) }},-
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalTransactionUsers">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/dollar-square.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Total Transacting Users</span>
          <img
            v-b-tooltip.hover.top="'Total seluruh ID User yang pernah mengalami minimal 1 orderan sudah pernah SELESAI'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ metrics.total_transacting_user }}
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalLtvUsers">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/health.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Net LTV per user</span>
          <img
            v-b-tooltip.hover.top="'Nilai kontribusi rata-rata pendapatan yang diberikan oleh user kepada kita selama masa hidupnya.'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ IDR(metrics.net_ltv_user) }},-
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalRetention">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/trend-up.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Retention Rate</span>
          <img
            v-b-tooltip.hover.top="'Metrics untuk mengukur tingkat pemakaian berulang dari existing user. Perhitungannya adalah jumlah total id user 2 BULAN LALU yang orderannya minimal 1 saja sudah SELESAI, kemudian dari user id YANG SAMA tersebut berapa persen % yang mengulangi orderan SELESAI di BULAN LALU. '"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ metrics.retention_rate }}%
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalCurrentUsers">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/user-square.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Current Curn User</span>
          <img
            v-b-tooltip.hover.top="'Mengukur jumlah total user yang berhenti menggunakan platform kita. Perhitungannya adalah jumlah total id user yang pernah orderannya minimal 1 sudah SELESAI (dikecualikan FAKE dan BATAL) namun dalam 3 minggu terakhir terhitung dari tanggal SEKARANG, tidak ada aktivitas pengajuan Pickup'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ metrics.curent_churn_user }}
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalChunPerios">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/watch.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Churn Period</span>
          <img
            v-b-tooltip.hover.top="'Dana user yang available namun belum ditarik'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ metrics.churn_period }}
        </span>
      </BCard>
    </BCol>
    <BCol lg="6">
      <BCard class="cards totalTransactionUsers">
        <img
          src="https://storage.googleapis.com/komerce/assets/svg/receive-square-green.svg"
          alt="Komerce"
          width="25"
        >
        <div class="flex items-center">
          <span class="mr-1">Outstanding User Balance</span>
          <img
            v-b-tooltip.hover.top="'Dana user yang available namun belum ditarik'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt="Komerce"
            class="cursor-pointer"
          >
        </div>
        <BSpinner
          v-if="isLoadingMetrics"
          small
          variant="primary"
        />
        <span
          v-else
          class="font-semibold"
        >
          {{ IDR(metrics.outstanding_user_balance) }},-
        </span>
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import { IDR } from '@/libs/currency'

export default {
  components: { BRow, BCol, BSpinner },
  props: {
    isLoadingMetrics: {
      type: Boolean,
      requred: true,
    },
    metrics: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      IDR,
    }
  },
}
</script>

<style scoped lang="scss">
@import './MetricsFinancial.scss';
</style>
