/* eslint-disable import/prefer-default-export */
export const configTable = [
  {
    key: 'cost_date',
    label: 'Tgl',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
    },
    tdClass: 'text-black font-medium',
    thClass: 'text-black',
    class: 'align-middle text-center',
  },
  {
    key: 'cost',
    label: 'Cost',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
    },
    tdClass: 'text-black font-medium',
    thClass: 'text-black',
    class: 'align-middle',
  },
  {
    key: 'registers',
    label: 'Register',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
    },
    tdClass: 'text-black font-medium',
    thClass: 'text-black',
    class: 'align-middle',
  },
  {
    key: 'orders',
    label: 'Order',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
    },
    tdClass: 'text-black font-medium',
    thClass: 'text-black',
    class: 'align-middle',
  },
  {
    key: 'pickups',
    label: 'Pickup',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
    },
    tdClass: 'text-black font-medium',
    thClass: 'text-black',
    class: 'align-middle',
  },
  {
    key: 'action',
    label: 'Action',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
    },
    tdClass: 'text-black font-medium',
    thClass: 'text-black',
    class: 'align-middle',
  },
]
