<template>
  <div>
    <strong class="text-black text-bold text-2xl">Metrics Finansial</strong>
    <BRow class="mt-2">
      <BCol lg="7">
        <BCard>
          <BRow class="items-center justify-between">
            <strong class="text-black text-bold text-lg ml-1">Performa Cost per result</strong>
            <MonthlyPicker
              v-model="filter"
              date-format="MMM YYYY"
              :month-labels="monthlabel"
              :max="maxDatePicker"
              style="width: 50px; margin-right: 12em"
            />
          </BRow>
          <BOverlay
            :show="isLoadingaCpr"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity="0.5"
            rounded="sm"
          >
            <BTable
              ref="selectData"
              :items="items"
              :fields="fields"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              class="mt-1 max-h-[217vh] overflow-auto"
              select-mode="single"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(cost_date)="data">
                {{ DATE(data.item.cost_date) }}
              </template>
              <template #cell(cost)="data">
                <img
                  v-if="data.item.cost === 0 && !data.rowSelected"
                  src="https://storage.googleapis.com/komerce/assets/svg/add-circle.svg"
                  alt="Komerce"
                >
                <BFormInput
                  v-if="data.rowSelected"
                  v-model="data.item.cost"
                  style="width: 100px"
                  type="number"
                  class="m-auto"
                />
                <span v-if="data.item.cost !== 0">{{ IDRWithoutLbl(data.item.cost) }},-</span>
              </template>
              <template #cell(action)="data">
                <BButton
                  v-if="data.rowSelected"
                  variant="primary"
                  size="sm"
                  @click="onFinish(data.item)"
                >
                  <BSpinner
                    v-if="loadingSubmit"
                    small
                    variant="light"
                  />
                  Simpan
                </BButton>
                <img
                  v-if="data.item.cost !== null && !data.rowSelected"
                  src="https://storage.googleapis.com/komerce/assets/svg/notes.svg"
                  alt="Komerce"
                >
              </template>
              <template
                v-if="!isEmpty(items)"
                v-slot:custom-foot="data"
              >
                <BTr class="text-black font-semibold">
                  <BTd
                    v-for="(item, idx) in data.fields"
                    :key="idx"
                    class="costTotal"
                  >
                    <span v-if="item.key === 'cost_date'">Total</span>
                    <span v-if="item.key === 'cost'">{{ IDRWithoutLbl(total.total_cost) }},-</span>
                    <span v-if="item.key === 'registers'">{{ total.total_register }}</span>
                    <span v-if="item.key === 'orders'">{{ total.total_orders }}</span>
                    <span v-if="item.key === 'pickups'">{{ total.total_pickup }}</span>
                  </BTd>
                </BTr>
                <BTr class="text-black font-semibold">
                  <BTd
                    class="costResult"
                    colspan="2"
                  >
                    <span v-if="data.fields[0].key === 'cost_date'">Cost per result</span>
                  </BTd>
                  <BTd
                    v-for="(item, idx) in data.fields"
                    :id="item.key === 'action' || item.key === 'pickups' || item.key === 'orders' && 'unusedId'"
                    :key="idx"
                    class="costResult"
                  >
                    <span v-if="item.key === 'cost_date'">{{ IDRWithoutLbl(costResult.cpr_register) }},-</span>
                    <span v-if="item.key === 'cost'">{{ IDRWithoutLbl(costResult.cpr_order) }},-</span>
                    <span v-if="item.key === 'registers'">{{ IDRWithoutLbl(costResult.cpr_pickup) }},-</span>
                  </BTd>
                </BTr>
              </template>
            </BTable>
          </BOverlay>
        </BCard>
      </BCol>
      <BCol lg="5">
        <BCard>
          <strong class="text-black text-bold text-lg">Metrics Kunci</strong>
          <CardsMetrics
            :is-loading-metrics="isLoadingMetrics"
            :metrics="metrics"
          />
        </BCard>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BTr, BTd,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import {
  DATE, END_DATE_OF_MONTH, LABELMONTH, START_DATE_OF_MONTH,
} from '@/libs/filterDate'
import { axiosIns } from '@/libs/axios'
import { IDR, IDRWithoutLbl } from '@/libs/currency'
import MonthlyPicker from 'vue-monthly-picker'
import isEmpty from 'lodash/isEmpty'
import { configTable } from './config'
import CardsMetrics from './CardsMetrics.vue'

export default {
  components: {
    BRow, BCol, BFormInput, BTr, BTd, MonthlyPicker, CardsMetrics,
  },
  data() {
    return {
      items: [],
      fields: configTable,
      isLoadingaCpr: false,
      isLoadingMetrics: false,
      DATE,
      IDR,
      IDRWithoutLbl,
      loadingSubmit: false,
      total: {},
      costResult: {},
      metrics: {},
      monthlabel: LABELMONTH,
      filter: this.$moment(),
      isEmpty,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment()
        .endOf('month')
    },
  },
  watch: {
    filter: {
      handler() {
        this.getListData()
      },
    },
  },
  mounted() {
    this.getListData()
    this.getListDataMetrics()
    if (!isEmpty) {
      document.getElementById('unusedId').remove('td')
      document.getElementById('true').remove('td')
    }
  },
  methods: {
    async getListData() {
      this.isLoadingaCpr = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filter),
        end_date: END_DATE_OF_MONTH(this.filter),
      }
      const url = '/v1/metrics-financial/cpr'
      await axiosIns.get(url, { params })
        .then(res => {
          const { data_days, totals, cost_per_result } = res.data.data
          // eslint-disable-next-line camelcase
          this.items = data_days
          this.isLoadingaCpr = false
          // eslint-disable-next-line camelcase
          this.costResult = cost_per_result
          this.total = totals
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            }, 2000,
          )
          this.isLoadingaCpr = false
        })
    },
    async getListDataMetrics() {
      this.isLoadingMetrics = true
      const url = '/v1/metrics-financial/key-metrics'
      await axiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.metrics = data
          this.isLoadingMetrics = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            }, 2000,
          )
          this.isLoadingMetrics = false
        })
    },
    async onFinish(value) {
      this.loadingSubmit = true
      const { id, cost } = value
      const url = '/v1/metrics-financial/update-cpr'
      await axiosIns.put(url, { id, cost })
        .then(() => {
          this.loadingSubmit = false
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: 'Berhasil mengganti cost',
                variant: 'success',
              },
            }, 2000,
          )
          this.getListData()
          this.clearSelected()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            }, 2000,
          )
          this.loadingSubmit = false
        })
    },
    onRowSelected(data) {
      return data
    },
    clearSelected() {
      this.$refs.selectData.clearSelected()
    },
  },
}
</script>

<style scoped lang="scss">
@import './MetricsFinancial.scss';
</style>
